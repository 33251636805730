import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { map, Observable } from 'rxjs';

import { AnimationState } from '@shared/components/mobile-bottom-navigation/enums/animation-state.enums';
import { ItemNavigation } from '@shared/components/mobile-bottom-navigation/interfaces/bottom-navigation.interfaces';
import { BurgerComponent } from '@shared/components/burger/burger.component';
import { PageScrollingService } from '@shared/services/page-scrolling';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';
import { YmGoalAppointment } from '@shared/services/yandex-metrica/enums/appointment.goals';
import { HelixRoutes } from '@shared/enums/routes';

@Component({
  selector: 'app-mobile-bottom-navigation',
  templateUrl: './mobile-bottom-navigation.component.html',
  styleUrls: ['./mobile-bottom-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, BurgerComponent],
  providers: [PageScrollingService],
  animations: [
    trigger('flyInOut', [
      state(AnimationState.In, style({ transform: 'translateX(100%)' })),
      state(AnimationState.Out, style({ transform: 'translateX(0)' })),
      transition(`${AnimationState.In} <=> ${AnimationState.Out}`, [
        animate('0.2s ease-in-out'),
      ]),
    ]),
  ],
})
export class MobileBottomNavigationComponent {
  constructor(
    private navigationMenuService: NavigationMenuService,
    private pageScrolling: PageScrollingService
  ) {}

  animationState: AnimationState = AnimationState.In;

  itemsNavigation$: Observable<ItemNavigation[]> =
    this.navigationMenuService.appointmentUrl$.pipe(
      map((appointmentUrl) => [
        {
          title: 'Сдать анализы',
          icon: 'icon-tube',
          link: `/${HelixRoutes.Catalog}`,
          testId: 'adaptive-header-nav-catalog',
        },
        {
          title: 'Прием врачей',
          icon: 'icon-stethoscope',
          link: appointmentUrl,
          ymGoalName: appointmentUrl.includes(
            HelixRoutes.PractitionerAppointment
          )
            ? YmGoalAppointment.MobileNavigationDoctorAppointmentPageOpen
            : YmGoalAppointment.MobileNavigationDoctorAppointmentCatalogOpen,
          testId: 'adaptive-header-nav-doctor-appointment',
        },
        {
          title: 'Выезд на дом',
          icon: 'icon-ambulance-car',
          link: `/${HelixRoutes.MobileService}`,
          testId: 'adaptive-header-nav-ms',
        },
      ])
    );

  openBurger(): void {
    this.pageScrolling.togglePageScroll(true);
    this.animationState = AnimationState.Out;
  }

  closeBurger(): void {
    this.pageScrolling.togglePageScroll(false);
    this.animationState = AnimationState.In;
  }
}
