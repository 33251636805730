import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { Link, Navigation } from '@shared/components/footer/types/menu.model';

@Component({
  selector: 'app-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class NavigationListComponent {
  @Input() list: Navigation | undefined;

  trackBy(index: number, item: Link): string {
    return item.title;
  }
}
