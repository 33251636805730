import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { StorageService } from '@shared/core/services/storage.service';
import { UpperFirstLetterPipe } from '@shared/pipes/upper-first-letter.pipe';
import { CityFormService } from '../../services/city-form.service';
import { CityRepositoryService } from '../../services/city-repository.service';

@Component({
  selector: 'app-city-search',
  standalone: true,
  imports: [CommonModule, UpperFirstLetterPipe],
  templateUrl: './city-search.component.html',
  styleUrls: ['./city-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitySearchComponent implements OnInit, OnDestroy {
  constructor(
    public formService: CityFormService,
    public repository: CityRepositoryService,
    public storage: StorageService
  ) {}

  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.formService.form.controls.search.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((value) => {
        this.repository.setSearchPhrase(value ?? '');
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
