import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { logoSvg } from '@shared/components/footer/constants/logo.svg';

import nationalBrandSrc from '!!file-loader!./../../assets/national-brand.png';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, SafeHtmlPipe],
})
export class LogoComponent {
  protected readonly nationalBrandSrc = nationalBrandSrc;
  protected readonly logoSvg = logoSvg;
}
