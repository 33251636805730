import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { Agreements } from '@shared/constants/agreements';
import { Link } from '@shared/components/footer/types/menu.model';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class SubNavigationComponent {
  protected readonly Agreements = Agreements;

  @Input() subNavigation: Link[] | undefined;

  trackBy(index: number, item: Link): string {
    return item.title;
  }
}
