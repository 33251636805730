import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-mobile-app-link',
  templateUrl: './mobile-app-link.component.html',
  styleUrls: ['./mobile-app-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MobileAppLinkComponent {
  @Input() href = '';
  @Input() ariaLabel = '';
}
