<div class="typography typography-body typography-bold mb-8">Телефон</div>

<a
  class="typography typography-h4 grid"
  href="tel:{{ companyPhone }}"
  *ngIf="phoneNumbers?.company as companyPhone"
>
  {{ companyPhone }}
</a>

<a
  class="typography typography-h4 grid mt-4"
  href="tel:{{ federalPhone }}"
  *ngIf="phoneNumbers?.federal as federalPhone"
>
  {{ federalPhone }}
</a>

<div class="typography typography-caption clue-line-height gray-600 mt-8">
  Ответим на любые вопросы <br />
  по работе и услугам
</div>
