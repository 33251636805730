import { NgModule } from '@angular/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ModalWithTimerComponent } from './components/modal-with-timer/modal-with-timer.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { BulletListComponent } from './components/bullet-list/bullet-list.component';
import { FormatTitleModule } from './pipes/format-title/format-title.module';

const sharedComponents = [
  RecaptchaComponent,
  BulletListComponent,
  ModalWithTimerComponent,
];

@NgModule({
  declarations: [sharedComponents],
  exports: [sharedComponents],
  providers: [Title],
  imports: [
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CommonModule,
    FormatTitleModule,
  ],
})
export class SharedModule {}
