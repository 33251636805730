export const MobileApps = {
  /**
   * Ссылка на мобильное приложение Helix.
   * На мобильных устройствах направляет на нужный маркетплейс,
   * на десктопе - на главную страницу сайта.
   */
  autodetectLink:
    'https://redirect.appmetrica.yandex.com/serve/389295680234981388',
  googlePlayLink:
    'https://redirect.appmetrica.yandex.com/serve/460522357451193186',
  appleStoreLink:
    'https://redirect.appmetrica.yandex.com/serve/28176801175962983',
  appGalleryLink:
    'https://redirect.appmetrica.yandex.com/serve/532769947806390515',
};

export const HelixExpressMobileApp = {
  // Сама определяет, в какой магазин приложений отправить пользователя
  autodetectStoreLinkExpress:
    'https://redirect.appmetrica.yandex.com/serve/893494407737674647',
  googlePlayLinkExpress:
    'https://redirect.appmetrica.yandex.com/serve/532355525556316651?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}',
  appleStoreLinkExpress:
    'https://redirect.appmetrica.yandex.com/serve/1036758687093369579?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}',
  googlePlayLink:
    'https://redirect.appmetrica.yandex.com/serve/244186275161129499?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}',
  appleStoreLink:
    'https://redirect.appmetrica.yandex.com/serve/1108878349616377248?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}',
};
