import { IConfiguration, YaMaps } from '../types/configuration.type';

export class Configuration implements IConfiguration {
  clientStorage = {
    isUserLoggedIn: false,
  };
  featureFlags = [];
  hosts = {
    main: 'https://helix.ru//',
    login: 'https://login.helix.ru/',
    b2b: 'https://b2b.helix.ru',
    doctorAccount: 'https://doctor.helix.ru/',
    personalAccount: 'https://my.helix.ru/',
  };
  phoneNumber = '+7 (800) 700 03 03';
  cityIdsWithPractitionerAppointment = [];
  recaptchaPublicKey = '';
  webim = {
    accountName: 'helix',
    domain: 'helix.webim.ru',
  };
  yandexMaps: YaMaps = {
    apiKey: '',
    locale: 'ru_RU',
  };
}
