<a
  class="block mt-4 mb-8"
  routerLink="/"
  aria-label="Главная"
  [innerHtml]="logoSvg | safeHtml"
></a>

<img
  class="img-brand"
  width="168"
  height="52"
  loading="lazy"
  decoding="auto"
  alt="Логотип компании"
  [src]="nationalBrandSrc"
/>
