import { Injectable } from '@angular/core';

import { HelixRoutes } from '@shared/enums/routes';
import { dzenSvg } from '../constants/dzen.svg';
import { telegramSvg } from '../constants/telegram.svg';
import { vkSvg } from '../constants/vk.svg';
import { Navigation, Link, SocialNetwork } from '../types/menu.model';

@Injectable()
export class FooterDatasourceService {
  navigation(): Navigation[] {
    return [
      new Navigation('Услуги клиентам', [
        new Link(`/${HelixRoutes.Centers}`, 'Адреса Центров Хеликс'),
        new Link(`/${HelixRoutes.Catalog}`, 'Сдать анализы'),
        new Link(`/${HelixRoutes.MobileService}`, 'Выезд на дом и в офис'),
        new Link('https://helzy.ru', 'Проверить симптомы', true),
        new Link(`/${HelixRoutes.Pages}/52`, 'Прием по ДМС'),
      ]),
      new Navigation('Справочная информация', [
        new Link(`/${HelixRoutes.Pages}/59`, 'Подготовка к анализам'),
        new Link(`/${HelixRoutes.Pages}/86`, 'Как получить результат'),
        new Link(`/${HelixRoutes.Helixbook}`, 'Helixbook'),
        new Link(`/${HelixRoutes.Pages}/65`, 'О Личном кабинете'),
      ]),
      new Navigation('Скидки и акции', [
        new Link(`/${HelixRoutes.Promotions}`, 'Скидки и акции'),
        new Link(`/${HelixRoutes.Loyalty}`, 'Программа лояльности'),
      ]),
      new Navigation('О Компании', [
        new Link(`/${HelixRoutes.Pages}/46`, 'Лицензии и сертификаты'),
        new Link(`/${HelixRoutes.Pages}/116`, 'Контроль качества'),
        new Link(`/${HelixRoutes.Publications}`, 'Пресса о нас'),
        new Link(`/${HelixRoutes.Pages}/107`, 'Контакты'),
        new Link(`/${HelixRoutes.Job}`, 'Вакансии'),
      ]),
    ];
  }

  socialNetworks(): SocialNetwork[] {
    return [
      new SocialNetwork('https://vk.com/helix_lab', vkSvg, 'VK'),
      new SocialNetwork('https://t.me/helixfamily', telegramSvg, 'Telegram'),
      new SocialNetwork(
        'https://zen.yandex.ru/id/622f2b11c658c87624297943',
        dzenSvg,
        'Dzen'
      ),
    ];
  }

  subNavigation(): Link[] {
    return [
      new Link(`/${HelixRoutes.Sitemap}`, 'Карта сайта'),
      new Link(`/${HelixRoutes.Pages}/103`, 'СОУТ'),
      new Link(`/${HelixRoutes.Pages}/57`, 'Правовая информация'),
    ];
  }
}
