<div class="overflow-hidden">
  <searcher-result-count [count]="catalog().length"></searcher-result-count>
  <div class="list">
    <div *ngFor="let item of catalog()" class="list-item">
      <div class="grid justify-content-between grid-nowrap grid-gutter-20">
        <div class="grid grid-gutter-20 grid-item grid-nowrap grid-xs-8">
          <a
            [routerLink]="[HelixRoutes.CatalogItem, item.hxid]"
            [state]="{ ymGoalName: YmGoalCatalogItem.OpenFromSearcher }"
            (click)="closeResults()"
            class="grid-item"
          >
            <span class="tag">{{ item.hxid }}</span>
          </a>
          <a
            [routerLink]="[HelixRoutes.CatalogItem, item.hxid]"
            [state]="{ ymGoalName: YmGoalCatalogItem.OpenFromSearcher }"
            (click)="closeResults()"
            class="ellipsis grid-item"
          >
            <div
              class="typography typography-body grey-800 typography-max-lines-4"
              [title]="item.description"
              style="margin-top: 2px"
            >
              {{ item.description }}
            </div>
          </a>
        </div>

        <div class="grid grid-item grid-gutter-12 grid-nowrap">
          <div class="grid-item typography-align-end">
            <span
              class="typography typography-body typography-bold nowrap"
              style="line-height: 2"
            >
              {{ item.price }}&nbsp;{{ item.currency }}
            </span>
          </div>
          <div class="grid-item">
            <button
              *ngIf="
                (addCartItemService.hxids$ | async)?.includes(item?.hxid ?? '')
              "
              class="button button-primary button-xs full-width"
              disabled
            >
              <div class="icon icon-check"></div>
            </button>
            <ng-container
              *ngIf="
                !(addCartItemService.hxids$ | async)?.includes(item?.hxid ?? '')
              "
            >
              <button
                *ngIf="item.canBePreOrder"
                class="button button-primary button-xs full-width"
                (click)="addToCart(item.hxid ?? '')"
                ymGoal
                [nameGoal]="YmGoalSearcher.AddToCartButtonClick"
              >
                <div class="icon icon-plus"></div>
              </button>
              <button
                *ngIf="!item.canBePreOrder"
                class="button button-primary button-xs full-width"
                (click)="openCannotPreOrderModal(item)"
                ymGoal
                [nameGoal]="YmGoalSearcher.ShowCannotPreorderModal"
              >
                <div class="icon icon-plus"></div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
