<footer class="section footer-bottom-navigation bg-gray-50 mt-64">
  <div class="section-content pt-48 pb-40">
    <div class="grid grid-gutter-40">
      <div class="grid-item grid-3 grid-lg-4 grid-xs-12 grid grid-column">
        <app-logo />

        <div class="grid justify-content-between mt-40 gap-12">
          <app-phone-numbers
            *ngIf="phoneNumbers?.company || phoneNumbers?.federal"
            [phoneNumbers]="phoneNumbers"
          />

          <app-social-networks
            class="hidden block-xs"
            [socialNetworks]="socialNetworks"
            [isShowTitle]="true"
          />
        </div>
      </div>

      <div class="grid-item grid-grow hidden-xs">
        <div class="grid grid-gutter-24 justify-content-between">
          <app-navigation-list
            class="grid-item grid-3 grid-lg-6"
            *ngFor="let item of navigation; trackBy: trackBy"
            [list]="item"
          />
        </div>
      </div>
    </div>

    <div class="typography typography-body typography-bold mt-40 mb-8">
      В приложении удобнее
    </div>

    <div class="grid justify-content-between align-items-center">
      <app-mobile-apps-links />

      <app-social-networks
        class="hidden-xs"
        [socialNetworks]="socialNetworks"
      />
    </div>

    <div class="divider footer-divider"></div>

    <div class="grid justify-content-between">
      <app-copyright class="hidden-sm mr-12" />

      <div>
        <app-sub-navigation
          class="grid grid-gutter-28 grid-xs-column"
          [subNavigation]="subNavigation"
        />

        <app-copyright class="hidden block-sm mt-28" />
      </div>
    </div>
  </div>
</footer>
