<div class="overflow-hidden">
  <searcher-result-count [count]="knowledge().length"></searcher-result-count>

  <div class="grid grid-gutter-20">
    <div *ngIf="haveTests()" class="grid-item grid-12">
      <div class="typography typography-paragraph typography-headline">
        Анализы:
      </div>

      <div class="list">
        <div class="list-item" *ngFor="let test of getTests()">
          <div class="grid grid-nowrap grid-gutter-20">
            <a
              class="grid-item"
              [routerLink]="[HelixRoutes.HelixbookItem, test.hxid]"
              (click)="closeResults()"
            >
              <span class="tag">{{ test.hxid }}</span>
            </a>
            <a
              class="grid-item ellipsis"
              [routerLink]="[HelixRoutes.HelixbookItem, test.hxid]"
              (click)="closeResults()"
            >
              <div
                class="typography typography-body grey-800 typography-max-lines-4 ellipsis"
                style="margin-top: 2px"
              >
                {{ test.title }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="haveArticles()" class="grid-item grid-12">
      <div class="typography typography-paragraph typography-headline">
        Дополнительные статьи и статьи по заболеваниям:
      </div>
      <div class="list">
        <div class="list-item" *ngFor="let article of getArticles()">
          <a
            [routerLink]="[HelixRoutes.HelixbookItem, article.id]"
            (click)="closeResults()"
          >
            <div class="typography typography-body grey-800">
              {{ article.title }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
