import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialNetwork } from '@shared/components/footer/types/menu.model';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';

@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe],
})
export class SocialNetworksComponent {
  @Input() socialNetworks: SocialNetwork[] | undefined;
  @Input() isShowTitle = false;

  trackBy(index: number, item: SocialNetwork): string {
    return item.ariaLabel;
  }
}
