import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { addMilliseconds, differenceInMilliseconds, parseISO } from 'date-fns';

import { StorageService } from '@shared/core/services/storage.service';
import { PlatformService } from '@shared/services/platform.service';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { YmGoalMobileAppBanner } from '@shared/services/yandex-metrica/enums/mobile-app-banner.goals';
import { MobileApps } from '@shared/constants/mobile-apps';

import bannerImg from '!!file-loader!./assets/banner-image.svg';

@Component({
  selector: 'app-mobile-app-banner',
  templateUrl: './mobile-app-banner.component.html',
  styleUrls: ['./mobile-app-banner.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('visibilityAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MobileAppBannerComponent implements OnInit, OnDestroy {
  shouldShow$ = new BehaviorSubject<boolean>(false);

  protected readonly banner = bannerImg;
  private readonly autodetectLink = MobileApps.autodetectLink;
  private readonly hideDurationMs = 5 * 60 * 1000;

  private get doNotShowAppBannerUntil(): Date | null {
    const cachedDate =
      this.storage.getLayoutViewDataCache().doNotShowAppBannerUntil;
    if (!cachedDate) {
      return null;
    }
    return parseISO(cachedDate as unknown as string);
  }
  private set doNotShowAppBannerUntil(value: Date | null) {
    const layoutViewData = this.storage.getLayoutViewDataCache();
    this.storage.setLayoutViewDataCache({
      ...layoutViewData,
      doNotShowAppBannerUntil: value,
    });
  }
  private bannerTimer?: NodeJS.Timeout;

  constructor(
    private storage: StorageService,
    private platform: PlatformService,
    private ymService: YmService
  ) {}

  ngOnInit(): void {
    this.setShowState();
  }

  open(): void {
    this.ymService.reachGoal(YmGoalMobileAppBanner.MobileAppBannerOpen);
    window.open(this.autodetectLink, '_blank');
  }

  close(): void {
    this.ymService.reachGoal(YmGoalMobileAppBanner.MobileAppBannerClose);
    this.doNotShowAppBannerUntil = addMilliseconds(
      new Date(),
      this.hideDurationMs
    );
    this.setShowState();
  }

  private setShowState(): void {
    if (this.platform.isServer) return;

    const hideUntil = this.doNotShowAppBannerUntil;
    if (!hideUntil || hideUntil < new Date()) {
      this.shouldShow$.next(true);
    } else {
      this.shouldShow$.next(false);
      this.startNewShowUpTimer(hideUntil);
    }
  }

  private startNewShowUpTimer(showAfter: Date): void {
    this.ngOnDestroy();
    this.bannerTimer = setTimeout(() => {
      this.shouldShow$.next(true);
    }, differenceInMilliseconds(showAfter, new Date()));
  }

  ngOnDestroy(): void {
    clearTimeout(this.bannerTimer);
  }
}
