<div
  class="typography typography-body typography-bold mb-16"
  *ngIf="isShowTitle"
>
  Социальные сети
</div>

<a
  class="link"
  target="_blank"
  *ngFor="let item of socialNetworks; trackBy: trackBy"
  [href]="item.link"
  [attr.aria-label]="item.ariaLabel"
  [innerHTML]="item.icon | safeHtml"
></a>
