import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NzUploadFile } from 'ng-zorro-antd/upload';

import {
  FeedbackClient,
  FileParameter,
  UserFeedbackMessageSource,
  UserFeedbackMessageType,
} from '@medindex-webapi';
import { StorageService } from '@shared/core/services/storage.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ErrorMessages } from '@shared/types/error-messages.enum';
import { CustomValidators } from '@shared/validators/custom-validators';
import { Agreements } from '@shared/constants/agreements';
import { ModalSize } from '@shared/enums/modal-sizes';
import { WriteToHelixFileUploadComponent } from './components/write-to-helix-file-upload.component';
import { ModalSuccessSendComponent } from '../modal-success-send/modal-success-send.component';
import { ModalErrorSendComponent } from '../modal-error-send/modal-error-send.component';

export type WriteToHelixThemes = 'ifns' | 'proposal' | string;

/** Модалка с формой для отправки обращения в Хеликс. */
@Component({
  selector: 'app-write-to-helix-modal',
  standalone: true,
  templateUrl: './write-to-helix-modal.component.html',
  styleUrls: ['./write-to-helix-modal.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    WriteToHelixFileUploadComponent,
  ],
  providers: [FeedbackClient],
})
export class WriteToHelixModalComponent implements OnInit, OnDestroy {
  /** Выбранный тип обращения. */
  @Input() selectedMessageType = UserFeedbackMessageType.Question;

  /** Возможные типы обращения. */
  messageTypes: { id: UserFeedbackMessageType; label: string }[] = [
    { id: UserFeedbackMessageType.Question, label: 'Вопрос' },
    { id: UserFeedbackMessageType.Proposal, label: 'Предложение' },
    { id: UserFeedbackMessageType.Complaint, label: 'Жалоба' },
    { id: UserFeedbackMessageType.Gratitude, label: 'Благодарность' },
    {
      id: UserFeedbackMessageType.IssuingCertificate,
      label: 'Справка для налоговой',
    },
  ];

  /** Находимся ли в процессе отправки обращения. */
  isLoadingSubmit$ = new BehaviorSubject<boolean>(false);

  /** Публичный ключ Recaptcha. */
  recaptchaKey$ = this.storage.configuration$.pipe(
    map((config) => config.recaptchaPublicKey)
  );

  /** Описание полей формы. */
  form?: FormGroup;

  readonly Agreements = Agreements;

  /** Хранит информацию о файлах для отправки (будут в аттачментах итогового письма). */
  private files: FileParameter[] = [];

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    private modalService: ModalService,
    private feedbackClient: FeedbackClient
  ) {}

  ngOnInit() {
    this.recaptchaKey$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((recaptchaKey: string): void => {
        this.createForm(recaptchaKey);
      });
  }

  /** Показывает модалку для ошибки в Recaptcha. */
  onRecaptchaError(): void {
    this.modalService.createError(ErrorMessages.Common);
  }

  /**
   * Устанавливает текущий тип обращения.
   * @param messageType Тип обращения (если указан пустой, то будет установлен тип "Вопрос").
   */
  selectMessageType(messageType?: UserFeedbackMessageType | null): void {
    this.selectedMessageType = messageType ?? UserFeedbackMessageType.Question;
  }

  /**
   * Подготавливает файлы из загрузчика для будущей отправки.
   * @param files Файлы из загрузчика.
   */
  updateFiles(files: NzUploadFile[]): void {
    this.files = files.map((file) => ({
      fileName: file.name,
      data: file,
    }));
  }

  /** Отправляет отчет об ошибке и показывает результат отправки. */
  onSubmit(): void {
    if (this.form?.invalid) return;

    const { name, email, message, orderCode, recaptcha } = this.form?.value;

    this.isLoadingSubmit$.next(true);

    this.storage.cityId$
      .pipe(
        switchMap((cityId) =>
          this.feedbackClient
            // Прод: стоит Validators.required для рекапчи, так что к этому моменту она не пустая
            // Тест: не стоит валидатор для рекапчи, к этому моменту может быть пустой
            .setUserFeedback(
              recaptcha ?? '',
              cityId,
              name,
              email,
              this.selectedMessageType as UserFeedbackMessageType,
              UserFeedbackMessageSource.Site,
              message,
              orderCode,
              this.files
            )
        ),
        finalize(() => this.isLoadingSubmit$.next(false)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        next: (): void => {
          this.form?.reset();
          this.modalService.destroyAll();

          this.openModalSuccess();
        },
        error: (): void => {
          this.openModalError(ErrorMessages.Common);
        },
      });
  }

  /**
   * Создает описание полей формы.
   * @param recaptchaKey Публичный ключ Recaptcha.
   */
  private createForm(recaptchaKey: string): void {
    this.form = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(null, [
        Validators.required,
        CustomValidators.Email,
      ]),
      message: new FormControl('', Validators.required),
      orderCode: new FormControl('', CustomValidators.OrderCode),
      accept: new FormControl(false, Validators.requiredTrue),
      recaptcha: new FormControl(
        null,
        recaptchaKey ? Validators.required : null
      ),
    });
  }

  /** Открывает красивую модалку с таймером (5 с) об успешной отправке обращения. */
  private openModalSuccess(): void {
    this.modalService.create({
      nzTitle: '',
      nzContent: ModalSuccessSendComponent,
      nzStyle: {
        width: ModalSize.Form,
      },
      nzClassName: 'modal-with-bg',
      nzFooter: null,
    });
  }

  /**
   * Показывает попап о неудачной отправке обращения.
   * @param message Текст ошибки.
   */
  private openModalError(message: string): void {
    this.modalService.create({
      nzTitle: '',
      nzContent: ModalErrorSendComponent,
      nzComponentParams: {
        message,
      },
      nzStyle: {
        width: ModalSize.Error,
      },
      nzBodyStyle: {
        padding: '80px 28px',
      },
      nzFooter: null,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
