import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterDatasourceService } from './services/footer-datasource.service';
import { LogoComponent } from './components/logo/logo.component';
import { PhoneNumbers } from './types/phone-numbers.model';
import { Navigation } from './types/menu.model';
import { MobileAppsLinksComponent } from '../mobile-apps-links/mobile-apps-links.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { NavigationListComponent } from './components/navigation-list/navigation-list.component';
import { PhoneNumbersComponent } from './components/phone-numbers/phone-numbers.component';
import { SocialNetworksComponent } from './components/social-networks/social-networks.component';
import { SubNavigationComponent } from './components/sub-navigation/sub-navigation.component';

@Component({
  selector: 'app-helix-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LogoComponent,
    MobileAppsLinksComponent,
    PhoneNumbersComponent,
    NavigationListComponent,
    SocialNetworksComponent,
    SubNavigationComponent,
    CopyrightComponent,
  ],
  providers: [FooterDatasourceService],
})
export class FooterComponent {
  @Input() phoneNumbers: PhoneNumbers | undefined;

  protected readonly navigation = this.datasource.navigation();
  protected readonly socialNetworks = this.datasource.socialNetworks();
  protected readonly subNavigation = this.datasource.subNavigation();

  constructor(private datasource: FooterDatasourceService) {}

  trackBy(index: number, item: Navigation): string {
    return item.title;
  }
}
