import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { MobileApps } from '../../constants/mobile-apps';
import { MobileAppLinkComponent } from './components/mobile-app-link/mobile-app-link.component';

@Component({
  selector: 'app-mobile-apps-links',
  templateUrl: './mobile-apps-links.component.html',
  styleUrls: ['./mobile-apps-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MobileAppLinkComponent],
})
export class MobileAppsLinksComponent {
  @Input()
  googlePlayLink = MobileApps.googlePlayLink;

  @Input()
  appleStoreLink = MobileApps.appleStoreLink;

  @Input()
  appGalleryLink = MobileApps.appGalleryLink;
}
