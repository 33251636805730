<div class="typography typography-body typography-bold mb-24">
  {{ list?.title }}
</div>

<div
  class="grid grid-column grid-gutter-12"
  *ngFor="let navItem of list?.items; trackBy: trackBy"
>
  <a
    class="grid-item typography typography-body"
    *ngIf="!navItem.isHrefLink"
    [routerLink]="navItem.link"
  >
    {{ navItem.title }}
  </a>

  <a
    class="grid-item typography typography-body"
    *ngIf="navItem.isHrefLink"
    [href]="navItem.link"
  >
    {{ navItem.title }}
  </a>
</div>
